import { DropdownOptionIntegerId } from '@/models/DropdownOptionIntegerId';
import { DropdownOption } from '@/models/DropdownOption';

export class SourceObjectLookupData {
    platforms: Array<DropdownOptionIntegerId>;
    dataSets: Array<DropdownOptionIntegerId>;
    dataOwners: Array<DropdownOptionIntegerId>;
    dataClassificationDetails: Array<DropdownOptionIntegerId>;
    objectTypes: Array<DropdownOptionIntegerId>;
    integrationTypes: Array<DropdownOptionIntegerId>;
    loadUpdateTypes: Array<DropdownOptionIntegerId>;
    objectFormat: Array<DropdownOptionIntegerId>;
    triggerTypes: Array<DropdownOptionIntegerId>;
    dataArchivalTypes: Array<DropdownOptionIntegerId>;
    ingestionTypes: Array<DropdownOptionIntegerId>;
    auditIsoCountryKeys: Array<DropdownOptionIntegerId>;
    geoCoverageLevels: Array<DropdownOptionIntegerId>;
    businessGroupCoverageCodes: Array<DropdownOption>;
    subFunctionCodes: Array<DropdownOption>;
}