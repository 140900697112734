import { Getter } from 'vuex-class'
import store from '@/store'
import { Component, Watch } from 'vue-property-decorator'
import { ObjectDataListBase } from '@/models/ObjectDataListBase';
import { IDataObjectList } from '@/interfaces/IDataObjectList'
import { IDataObjectListItem } from '@/interfaces/IDataObjectListItem'
import { DropdownOption } from '@/models/DropdownOption';

@Component
export class ObjectDataListEditorBase<TItem, TListItem extends IDataObjectListItem, TList extends IDataObjectList<TListItem>, TLookupData, TFilteredLookupData, TFilterOptions> extends ObjectDataListBase<TListItem, TList, TFilteredLookupData, TFilterOptions> {
    protected editedItem: TItem;
    protected lookupData: TLookupData;
    protected editDialog = false;
    protected editItemIsValid = true;

    // currently a hardcoded list
    public updateTypes: Array<DropdownOption> = [
        {
            id: 'UNDEFINED', text: 'UNDEFINED'
        },
        {
            id: 'Incremental - Append', text: 'Incremental - Append'
        },
        {
            id: 'Incremental - Upsert', text: 'Incremental - Upsert'
        },
        {
            id: 'Incremental - Bi - temporal', text: 'Incremental - Bi - temporal'
        },
        {
            id: 'Snapshot', text: 'Snapshot'
        },
        {
            id: 'Incremental - Upsert with delete and if Condition', text: 'Incremental - Upsert with delete and if Condition'
        },
        {
            id: 'Real Time', text: 'Real Time'
        },
        {
            id: 'Full', text: 'Full'
        },
        {
            id: 'Incremental - Upsert with NO delete', text: 'Incremental - Upsert with NO delete'
        },
        {
            id: 'Micro Batch Incremental', text: 'Micro Batch Incremental'
        }
    ];

    // this is being used instead of a boolean and checkbox because the existing template and db table requires the text input not the id lookup value
    public yesNoList: Array<DropdownOption> = [
        {
            id: 'YES', text: 'YES'
        },
        {
            id: 'NO', text: 'NO'
        },
        {
            id: 'UNDEFINED', text: 'UNDEFINED'
        }
    ];

    public ynList: Array<DropdownOption> = [
        {
            id: 'Y', text: 'Y'
        },
        {
            id: 'N', text: 'N'
        }
    ];

    public extractTypes: Array<DropdownOption> = [
        {
            id: 'UNDEFINED', text: 'UNDEFINED'
        },
        {
            id: 'Incremental', text: 'Incremental'
        },
        {
            id: 'Full-0', text: 'Full-0'
        },
        {
            id: 'Full-21', text: 'Full-21'
        },
        {
            id: 'Real Time', text: 'Real Time'
        },
        {
            id: 'Micro Batch Incremental', text: 'Micro Batch Incremental'
        }
    ];

    public requestTypes: Array<DropdownOption> = [
        {
            id: 'UNDEFINED', text: 'UNDEFINED'
        },
        {
            id: 'New', text: 'New'
        },
        {
            id: 'Change', text: 'Change'
        }
    ];

    public globalOrRegionals: Array<DropdownOption> = [
        {
            id: 'G', text: 'G'
        },
        {
            id: 'R', text: 'R'
        },
        {
            id: 'X', text: 'X'
        },
        {
            id: 'T', text: 'T'
        },
        {
            id: '?', text: '?'
        }
    ];

    public dataCoverages: Array<DropdownOption> = [
        {
            id: 'Cordillera', text: 'Cordillera'
        },
        {
            id: 'Fusion', text: 'Fusion'
        },
        {
            id: 'Global', text: 'Global'
        },
        {
            id: 'Latam', text: 'Latam'
        },
        {
            id: 'Regional', text: 'Regional'
        },
        {
            id: 'Sirius', text: 'Sirius'
        },
        {
            id: 'Template', text: 'Template'
        },
        {
            id: 'U2K2', text: 'U2K2'
        },
        {
            id: 'UNDEFINED', text: 'UNDEFINED'
        }
    ];

    public internalExternals: Array<DropdownOption> = [
        {
            id: 'Example', text: 'Example'
        },
        {
            id: 'External', text: 'External'
        },
        {
            id: 'Internal', text: 'Internal'
        },       
        {
            id: 'UNDEFINED', text: 'UNDEFINED'
        }
    ];


    public platformLayers: Array<DropdownOption> = [
        {
            id: 'BDL', text: 'BDL'
        },
        {
            id: 'DAP', text: 'DAP'
        },
        {
            id: 'DDL', text: 'DDL'
        },
        {
            id: 'DOC', text: 'DOC'
        },
        {
            id: 'GLS', text: 'GLS'
        },
        {
            id: 'MDB', text: 'MDB'
        },
        {
            id: 'PDS', text: 'PDS'
        },
        {
            id: 'REQ', text: 'REQ'
        },
        {
            id: 'UDL', text: 'UDL'
        }
    ];

    public platformRegions: Array<DropdownOption> = [
        {
            id: 'Cordillera', text: 'Cordillera'
        },
        {
            id: 'Fusion', text: 'Fusion'
        },
        {
            id: 'Global', text: 'Global'
        },
        {
            id: 'Latam', text: 'Latam'
        },
        {
            id: 'Regional', text: 'Regional'
        },
        {
            id: 'Sirius', text: 'Sirius'
        },
        {
            id: 'Template', text: 'Template'
        },
        {
            id: 'U2K2', text: 'U2K2'
        },
        {
            id: 'UNDEFINED', text: 'UNDEFINED'
        }
    ];


    public sortOrders: Array<number> = [];

    protected async getItem(id: number) {
        await this.$axios.get<TItem>(this.apiUrlBase + id)
            .then(response => {
                this.editedItem = Object.assign({}, response.data);
            })
            .catch(err => {
                this.showErrorMessage(err, "Could not get item");
                return Promise.reject(err);
            });
    }

    protected async getLookupData() {
        await this.$axios.get<TLookupData>(this.apiUrlBase + "lookup-data")
            .then(response => {
                this.lookupData = response.data;
            })
            .catch(err => {
                this.showErrorMessage(err, "Could not get lookup data");
                return Promise.reject(err);
            });
    }

    protected editItem(item: TListItem) {
        this.showOverlay();
        store.dispatch("events/setBatchLock").then(() => {
            this.isBatchLocked = store.getters["events/isBatchLocked"];
        }).then(() => {
            if (this.isBatchLocked) {
                this.hideOverlay();
            } else {
                Promise.all([this.getLookupData(), this.getItem(item.id)])
                    .then(x => {
                        this.editDialog = true;
                    }).finally(() => {
                        this.hideOverlay();
                    });
            }
        })
    }

    private async save() {
        this.closeEditDialog();
        this.showOverlay();

        store.dispatch("events/setBatchLock").then(() => {
            this.isBatchLocked = store.getters["events/isBatchLocked"];
        }).then(() => {
            if (this.isBatchLocked) {
                this.hideOverlay();
            } else {
                if (this.editItemIsValid) {
                    this.$axios.post(this.apiUrlBase, this.editedItem)
                        .then(response => {
                            this.showResponseFeedback(response);
                            this.getItemsFiltered();
                        })
                        .catch(err => {
                            this.showErrorMessage(err);
                        }).finally(() => {
                            this.hideOverlay();
                        });
                }
            }
        });

    }

    protected closeEditDialog() {
        this.editDialog = false;
    }

    @Watch('editDialog')
    private editDialogChange(val: boolean) {
        val || this.closeEditDialog();
    }

    created() {
        for (let i = 0; i <= 9999; i++) { this.sortOrders.push(i); }
    }

    constructor() {
        super();
    }
}
