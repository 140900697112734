import { DropdownOptionIntegerId } from '@/models/DropdownOptionIntegerId';
import { DropdownOption } from '@/models/DropdownOption';

export class TargetObjectLookupData {
    platforms: Array<DropdownOptionIntegerId>; 
    targetObjectTypes: Array<DropdownOptionIntegerId>;
    dataSets: Array<DropdownOptionIntegerId>;
    dataOwners: Array<DropdownOptionIntegerId>;
    dataArchivalTypes: Array<DropdownOptionIntegerId>;
    complexityEstimates: Array<DropdownOption>;
    dataClassificationDetails: Array<DropdownOptionIntegerId>;
    techDeletePatterns: Array<DropdownOptionIntegerId>;
    auditIsoCountryKeys: Array<DropdownOptionIntegerId>;
    geoCoverageLevels: Array<DropdownOptionIntegerId>;
    //geoCoverageValueCodes: Array<DropdownOptionIntegerId>;;
    businessGroupCoverageCodes: Array<DropdownOption>;
    subFunctionCodes : Array<DropdownOption>;
}
